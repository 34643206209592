(function(){
  // const page = document.getElementById('page-content');
  const page = document.querySelector('body');
  const sideMenu = document.getElementById('overlay-navigation');
  const showMenuButton = document.getElementById('show-menu-button');

  const toggleMenu = () => {
    [
      page,
      sideMenu,
      showMenuButton,
    ].forEach(e => e.classList.toggle('active'));
  };
  
  const hideMenu = () => {
    [
      page,
      sideMenu,
      showMenuButton,
    ].forEach(e => e.classList.remove('active'));
  };

  document.querySelectorAll('.page-scroll').forEach(e => e.onclick = hideMenu);
  
  showMenuButton.onclick = toggleMenu;
})();